.navbar.navbar-payplan {
    background-color: #fff;
    border: 0 none;
    margin-bottom: 0;

    > .container {
        > .row {
            display: table;
            width: 100%;
            margin-left: 0;
            margin-right: 0;

            > [class^="col-xs-"] {
                display: table-cell;
                vertical-align: middle;
                float: none;
                padding: 0;
            }
        }
    }

    .telephone {
        [href^="tel:"] {
            color: $logo-blue;
            font-weight: 700;
            font-style: normal;

            &:hover,
            &:focus {
                text-decoration: none;
            }
        }
    }

    .navbar-header {

        margin-left: ($grid-gutter-width/2);
        width: 100%;
        padding: 1.25rem 0;

        @include target_ie_below_edge {
            padding: 0;
        }

        @include respond-to(bp-small) {
            margin-left: 0;
        }

        .logo {
            display: block;
            width: 100%;
            max-width: ($logo-width + ($logo-width/2)) + px;
        }
    }    
}