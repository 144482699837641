%visuallyhidden {
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
}

.visually-hidden {
    @extend %visuallyhidden;
}

.m-0 {
    margin: 0 !important;
}
.m-t-0,
.m-y-0 {
    margin-top: 0 !important;
}
.m-t-5,
.m-y-5 {
    margin-top: ($grid-gutter-width / 4) !important;
}
.m-t-10,
.m-y-10 {
    margin-top: ($grid-gutter-width / 2) !important;
}
.m-t-15,
.m-y-15 {
    margin-top: ($grid-gutter-width - 5px) !important;
}
.m-t-20,
.m-y-20 {
    margin-top: ($grid-gutter-width) !important;
}
.m-r-0,
.m-x-0 {
    margin-right: 0 !important;
}
.m-b-0,
.m-y-0 {
    margin-bottom: 0 !important;
}
.m-b-5,
.m-y-5 {
    margin-bottom: ($grid-gutter-width / 4) !important;
}
.m-b-10,
.m-y-10 {
    margin-bottom: ($grid-gutter-width / 2) !important;
}
.m-b-15,
.m-y-15 {
    margin-bottom: ($grid-gutter-width - 5px) !important;
}
.m-l-0,
.m-x-0 {
    margin-left: 0 !important;
}

.m-l-5{
    margin-left:5px !important;
}

.p-0 {
    padding: 0 !important;
}
.p-t-0,
.p-y-0 {
    padding-top: 0 !important;
}
.p-t-5,
.p-y-5 {
    padding-top: ($grid-gutter-width / 4) !important;
}
.p-t-10,
.p-y-10 {
    padding-top: ($grid-gutter-width / 2) !important;
}
.p-t-15,
.p-y-15 {
    padding-top: ($grid-gutter-width - 5px) !important;
}
.p-r-0,
.p-x-0 {
    padding-right: 0 !important;
}
.p-b-0,
.p-y-0 {
    padding-bottom: 0 !important;
}
.p-b-5,
.p-y-5 {
    padding-bottom: ($grid-gutter-width / 4) !important;
}
.p-b-10,
.p-y-10 {
    padding-bottom: ($grid-gutter-width / 2) !important;
}
.p-b-15,
.p-y-15 {
    padding-bottom: ($grid-gutter-width - 5px) !important;
}
.p-l-0,
.p-x-0 {
    padding-left: 0 !important;
}

.rounded {
    border-radius: $border-radius-base;
}