

/* originally authored by Nick Pettit - https://github.com/nickpettit/glide */

@keyframes pulse {
    from {
        transform: scale3d(1, 1, 1);
    }

    50% {
        transform: scale3d(1.1, 1.1, 1.1);
    }

    to {
        transform: scale3d(1, 1, 1);
    }
}

.pulse {
    animation-name: pulse;
}