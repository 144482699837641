.control-label {
    .capitalise {
        @include respond-to(bp-small) {
            text-transform: capitalize;
        }
    }
}

.form-signin-heading {
    color: $purple;
    text-transform: uppercase;
    font-size: 1.4em;
    font-weight: 700;
}

.form-horizontal .control-label {
    text-align: left;

    &.password {
        font-weight: 700;
        padding-top: 0;
    }

    &.legend {
        font-size: 125%;
        font-weight: 700;
        font-variant: small-caps;
        text-transform: lowercase;
        border: 0 none;
        @include respond-to(bp-small) {
            font-size: 100%;
            font-weight: normal;
            font-variant: normal;
            text-transform: none;
        }
    }
}

.panel-body .form-group:last-child {
    margin-bottom: 0;
}

.input-group-addon,
.form-control,
.input-group-btn .btn {
    padding: 6px 8px;
}

.input-group {
    position: relative;

    input[type="number"] {
        text-align: center;
        -moz-appearance:textfield;

        &::-webkit-inner-spin-button,
        &::-webkit-outer-spin-button {
            -webkit-appearance: none;
            margin: 0;
        }
    }

    [readonly] {
        @include box-shadow(none);
    }

    &-btn {
        &:not(:first-child):not(:last-child) {
            .btn {
                border-radius: 0;
                margin-left: -1px;
            }
            + .input-group-addon {
                @include border-left-radius(0);
            }
        }        

        .has-error & {
            border-color: $state-danger-text;
            .btn {
                border-color: $state-danger-text;
            }
        }
        .has-success & {
            border-color: $state-success-text;
            .btn {
                border-color: $state-success-text;
            }
        }
        .has-warning & {
            border-color: $state-warning-text;
            .btn {
                border-color: $state-warning-text;
            }
        }
    }
}

.form-group.has-error .form-control + ul {
    padding-left: 0;
}

[role="radiogroup"] .tip_anchor {
    margin-right: 9px;
    margin-top: 7px;
    z-index: +1;
}

.form-hint {
    background-color: Black;
    border-radius: $input-border-radius;
    color: White;
    padding: 5px;
    font-size: 11px;
    display: none;
    position: absolute;
    bottom: 0px;
    left: 0px;
    transform: translateY(110%);
    z-index: $zindex-tooltip;

    &::before {
        border-bottom: 5px solid black;
        border-top: 0 none;
        border-left: 10px solid transparent;
        border-right: 10px solid transparent;
        content: "";
        position: absolute;
        top: 0;
        left: 50%;
        transform: translate(-50%, -100%);
        margin: 0 auto;
        display: block;
        height: 0;
        width: 0;
        z-index: $zindex-tooltip;
    }

    .form-control:focus + & {
        display: block;
    }
}

.form-control {
    &.car-reg {
        text-transform: uppercase;
        font-weight: bold;
        background-color: #FFC72C;
        color: black;
        text-align: center;
    }
}

textarea.form-control {

    &.flexi {
        height: ($input-height-base * 2);
        transition: height .3s ease;

        &:focus,
        &:active,
        &:not(:empty) {
            height: ($input-height-base * 5);
        }
    }
}

.block-link {
    text-align: center;
    display: block;
    margin-top: 5px;
}