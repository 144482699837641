@include keyframes(toLandscape) {
    0%, 40% { transform: rotate(0deg); }
    50%, 100% { transform: rotate(90deg); }
}
.orientation {
    &--portrait {
        display: none;
        @media (max-width: $screen-xs-max) and (orientation:portrait) {
            display: block;
            width: 100%;
            height: 100%;
            // vertical-align: middle;
            @include abs-pos(0px 0px 0px 0px);
            background-color: $body-bg;
            // overflow: hidden;
            text-align: center;
            // min-height: ($screen-xs-max/2);

            img {
                max-width: 25%;
                width: 100%;
                position: relative;
                // top: 10%;
                // left: 50%;
                @include animate(toLandscape 4s infinite linear);
                transform-origin: center center;
            }
        }
    }
    // &--landscape {
    //     @media (max-width: $screen-sm-min) and (orientation:landscape) {
    //         display: block;
    //         @include abs-pos(0px 0px 0px 0px);
    //         background-color: $body-bg;
    //     }
    // }
}