@font-face {
    font-family: 'PT Sans';
    src: local('PT Sans'), local('PTSans-Regular'), url("../fonts/PT_Sans-Web-Regular.ttf");
    font-weight: 400;
    font-style: normal;
}
@font-face {
    font-family: 'PT Sans';
    src: local('PT Sans Italic'), local('PTSans-Italic'), url("../fonts/PT_Sans-Web-Italic.ttf");
    font-weight: 400;
    font-style: italic;
}
@font-face {
    font-family: 'PT Sans';
    src: local('PT Sans Bold'), local('PTSans-Bold'), url("../fonts/PT_Sans-Web-Bold.ttf");
    font-weight: 700;
    font-style: normal;
}
@font-face {
    font-family: 'PT Sans';
    src: local('PT Sans Bold Italic'), local('PTSans-BoldItalic'), url("../fonts/PT_Sans-Web-BoldItalic.ttf");
    font-weight: 700;
    font-style: italic;
}
// @import url('//fonts.googleapis.com/css?family=PT+Sans:400,400i,700,700i');