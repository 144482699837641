// Themes 
// 
// Contains styling for different themes
// 
// Markup: _buttons.hbs
//
// .btn-primary - Class variant
// 
// Styleguide Themes.Buttons
@each $name, $value in $white_text_colors {
    .btn-#{$name} {
        @include button-variant(#fff, $value, $value);
        font-weight: 700;
        text-transform: uppercase;

        &:hover,
        &:focus,
        &.pseudo-class-hover {
            background-color: darken($value, 5%);
            // color: contrast-color(darken($value, 5%));
        }
    }
}

@each $name, $value in $btn_colors {
    .btn.bg-color-#{$name} {
        border-color: $value;

        &:hover,
        &:focus,
        &.pseudo-class-hover {
            border-color: darken($value, 10%);
        }
    }
}