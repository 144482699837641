/*.jquery-checkbox       {display: inline; font-size: 1.250em; line-height: 1.875em; cursor: pointer; cursor: hand;}
.jquery-checkbox .mark {display: inline;}

.jquery-checkbox img {vertical-align: middle; width: 76px; height: 32px; display: inline-block;}

.jquery-checkbox img{background: transparent url(../images/checkbox.png) no-repeat;}
//.svg .jquery-checkbox img{background: url(../images/checkbox.svg) no-repeat;}

.jquery-checkbox img{
	background-position: 0 0;
}
.jquery-checkbox-hover img{
	background-position: 0 -32px;
}
.jquery-checkbox-checked img{
	background-position: 0 -64px;
}
.jquery-checkbox-checked .jquery-checkbox-hover img {
	background-position: 0 -96px;
}

.jquery-checkbox-disabled img{
	background-position: 0 -128px;
}
.jquery-checkbox-checked .jquery-checkbox-disabled img{
	background-position: 0 -160px;
}*/

/*============================================================
//	iOS 6 style switch checkboxes
//	by Lea Verou http://lea.verou.me 
//==========================================================*/
.checkbox {
	&.switch {
		padding-top: 0;
		margin: 0;

		.form-horizontal & {
			padding-top: 0 !important;
		}

		label {
			padding-left: 0;
		}

		input[type="checkbox"] {
			position: absolute;
			opacity: 0;	

			&.ios-switch {
				+ span {
					border-radius: 4px;
					background-clip: padding-box;
					box-shadow: 0 .1em .1em rgba(0,0,0,.2) inset, 0 .45em 0 .1em rgba(0,0,0,.05) inset;
					display: inline-block;
					vertical-align: middle;
					width: 60px;
					height: 34px;
					border: 1px solid rgba(0,0,0,.3);
					background: white;
					background-image: linear-gradient(rgba(0,0,0,.1), transparent),
					                  linear-gradient(90deg, hsl(210, 90%, 60%) 50%, transparent 50%);
					background-size: 200% 100%;
					background-position: 100% 0;
					background-origin: border-box;
					background-clip: border-box;
					overflow: hidden;
					transition-duration: .4s;
					transition-property: padding, width, background-position, text-indent;
					font-size: 170%; /* change this and see how they adjust! */
					color: #555;
					margin-right: ($grid-gutter-width/4);

					&::before,
					&::after {
						font: bold 60%/1.9 sans-serif;
						text-transform: uppercase;
						padding-top: 0.075em;
					}
					&::before {
						background-image: linear-gradient(to bottom, rgba(0,0,0,.2) 0%, #eee 100%);
						box-shadow: 0 .1em .1em .1em hsla(0,0%,100%,.8) inset, 0 0 .5em rgba(0,0,0,.3);
						content: 'On';
						float: left;
						width: 10px; height: 32px;
						border: 0 none;
						border-radius: 3px;
						background: white;
						color: white;
						text-shadow: 0 -1px 1px rgba(0,0,0,.3);
						text-indent: -2.5em;
						text-align: left;						
					}					
					&::after {
						text-align: left;
						content: 'Off';
						float: left;
						text-indent: .6em;
						text-shadow: none;
					}					
				}

				&:checked {
					+ span {
						padding-left: 48px;
						background-position: 0 0;
					}
				}

				&:active {
					+ span {
						&::before {
							background-color: #fff;
						}
					}
				}

				&:focus {
					+ span {
						box-shadow: inset 0 1px 1px rgba(0,0,0,.075), 0 0 8px rgba(102,175,233,.6);
					    border-color: #66afe9;
					    outline: 0;
					}
				}

				&:disabled {
					+ span {
						opacity: 0.5;
						cursor: not-allowed;
						color: rgba(0,0,0,.25);
					}
				}
			}		
		}

		&.yn {
			input[type="checkbox"] {
				&.ios-switch {
					+ span {
						&::before {
							content: "Yes";
						}
						&::after {
							content: "No";
						}
					}
				}
			}
		}
	}
}	