.modal {


    // KBA styling
    .kbaq {
        legend {
            border: 0 none;
            font-size: 120%;
            font-weight: 600;
            margin-bottom: $form-group-margin-bottom;
        }
    }
}