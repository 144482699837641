@each $name, $value in $btn_colors {
    .bg-color-#{$name} {
        background-color: $value;
        // color: contrast-color($value);

        &:hover,
        &:focus {
            background-color: darken($value, 10%);
        }
    }

    .bg-color-#{$name}-hue {
        @include background-gradient(135deg, $value, 0, adjust-hue($value, 25%), 100%);
    }
}