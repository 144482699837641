@each $name, $value in $btn_colors {
    .bg-color-#{$name} {
        background-color: $value;
        // color: contrast-color($value);

        &:hover,
        &:focus {
            background-color: darken($value, 10%);
        }
    }
}
@each $name, $value in $btn_colors {
    .btn.bg-color-#{$name} {
        border-color: $value;

        &:hover,
        &:focus,
        &.pseudo-class-hover {
            border-color: darken($value, 10%);
        }
    }
}
@each $name, $value in $white_text_colors {
    .btn-#{$name} {
        @include button-variant(contrast-color($value), $value, darken($value, 5%));
        font-weight: 700;
        text-transform: uppercase;

        &:hover,
        &:focus,
        &.pseudo-class-hover {
            background-color: darken($value, 10%);
            color: contrast-color(darken($value, 10%));
        }
    }
}