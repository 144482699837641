.table {
    >thead>tr>td {
        border-bottom: 2px solid $panel-default-border;
    }
    .additional {
        display: block;
    }
    .btn-group {
        display: table;
        white-space: nowrap;
        .btn {
            display: table-cell;
            float: none;
        }
    }
    caption {
        color: $panel-default-text;
        background-color: $panel-default-heading-bg;
        border-color: $panel-default-border;
        line-height: 1.1;
        padding: $panel-heading-padding;
        @include border-top-radius(($panel-border-radius - 1));
    }
}