.alert {
    display: table;
    padding: 0;
    width: 100%;
    position: relative;

    &-icon,
    &-body {
        display: table-cell;
        padding: $alert-padding;
        vertical-align: top;
        @include respond-to(bp-small) {
            vertical-align: middle;
        }
    }

    &-body {
        width: 100%;

        p:last-child,
        p:only-child {
            margin-bottom: 0;
        }
    }

    &-icon {
        text-align: center;
        .glyphicon {
            font-size: 200%;

            &.animated::before {
                @extend .animated;
            }
        }
    }

    &-success {
        .alert-icon {
            background-color: $alert-success-border;
        }
      // @include alert-variant($alert-success-bg, $alert-success-border, $alert-success-text);
    }

    &-info {
        .alert-icon {
            background-color: $alert-info-border;
        }
      // @include alert-variant($alert-info-bg, $alert-info-border, $alert-info-text);
    }

    &-warning {
        .alert-icon {
            background-color: $alert-warning-border;
        }
      // @include alert-variant($alert-warning-bg, $alert-warning-border, $alert-warning-text);
    }

    &-danger {
        .alert-icon {
            background-color: $alert-danger-border;
        }
      // @include alert-variant($alert-danger-bg, $alert-danger-border, $alert-danger-text);
    }

    [data-dismiss="alert"] {
        position: absolute;
        right: ($alert-padding / 2);
        top: ($alert-padding / 2);
    }
}