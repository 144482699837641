@import "compass";
@import "vendors/module";
@import "utils/module";
@import "base/module";
@import "components/module";
@import "layout/module";
@import "pages/module";
@import "themes/module";

.kss-modifiers__example {
	[class^="col-"],
	[class^="inside"] {
		border: 1px solid Purple;
		background-color: Pink;
	}
}