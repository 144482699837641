// Equal Height Columns 
// 
// Equal height columns used with Grid layout.<br>
// Use grid sizes (<code>xs</code>, <code>sm</code>, <code>md</code> & <code>lg</code>) to control columns widths
// 
// Markup: _equal-height-columns.hbs
// 
// Styleguide Layout.Equal Height




// Full height elements 
// 
// Make elements within equal height columns span full height
// 
// Markup: _equal-height-columns-full-height.hbs
// 
// Styleguide Layout.Equal Height.Full height




// =============================================================================
// content styles
// =============================================================================
.inside {
    margin-top: 0;
    margin-bottom: 0;
}
.inside-full-height {
    height: 100%;
    margin-top: 0;
    margin-bottom: 0;
}

.col-padding {
    padding: $grid-gutter-width/2;

    h2 {
        margin-top: 0;
        text-transform: uppercase;
    }
}



// =============================================================================
// columns of same height styles
// =============================================================================
.row-height {
    display: table;
    table-layout: fixed;
    height: 100%;
    width: 100%;
    margin-bottom: $grid-gutter-width/2;
}
.col-height {
    display: table-cell;
    float: none !important;
    height: 100%;
}
.col-top {
    vertical-align: top;
}
.col-middle {
    vertical-align: middle;
}
.col-bottom {
    vertical-align: bottom;
}

$screen-sizes:  (xs, $screen-xs-min),
                (sm, $screen-sm-min),
                (md, $screen-md-min),
                (lg, $screen-lg-min);

@each $size, $screen in $screen-sizes {
    @media (min-width: #{$screen}) {
        .row-#{$size}-height {
            display: table;
            table-layout: fixed;
            height: 100%;
            width: 100%;
        }
        .col-#{$size}-height {
            display: table-cell;
            float: none !important;
            height: 100%;
        }
        .col-#{$size}-top {
            vertical-align: top;
        }
        .col-#{$size}-middle {
            vertical-align: middle;
        }
        .col-#{$size}-bottom {
            vertical-align: bottom;
        }
    }
}

// @media (min-width: $screen-xs-min) {
//     .row-xs-height {
//         display: table;
//         table-layout: fixed;
//         height: 100%;
//         width: 100%;
//     }
//     .col-xs-height {
//         display: table-cell;
//         float: none;
//         height: 100%;
//     }
//     .col-xs-top {
//         vertical-align: top;
//     }
//     .col-xs-middle {
//         vertical-align: middle;
//     }
//     .col-xs-bottom {
//         vertical-align: bottom;
//     }
// }

// @media (min-width: $screen-sm-min) {
//     .row-sm-height {
//         display: table;
//         table-layout: fixed;
//         height: 100%;
//         width: 100%;
//     }
//     .col-sm-height {
//         display: table-cell;
//         float: none;
//         height: 100%;
//     }
//     .col-sm-top {
//         vertical-align: top;
//     }
//     .col-sm-middle {
//         vertical-align: middle;
//     }
//     .col-sm-bottom {
//         vertical-align: bottom;
//     }
// }

// @media (min-width: $screen-md-min) {
//     .row-md-height {
//         display: table;
//         table-layout: fixed;
//         height: 100%;
//         width: 100%;
//     }
//     .col-md-height {
//         display: table-cell;
//         float: none;
//         height: 100%;
//     }
//     .col-md-top {
//         vertical-align: top;
//     }
//     .col-md-middle {
//         vertical-align: middle;
//     }
//     .col-md-bottom {
//         vertical-align: bottom;
//     }
// }

// @media (min-width: $screen-lg-min) {
//     .row-lg-height {
//         display: table;
//         table-layout: fixed;
//         height: 100%;
//         width: 100%;
//     }
//     .col-lg-height {
//         display: table-cell;
//         float: none;
//         height: 100%;
//     }
//     .col-lg-top {
//         vertical-align: top;
//     }
//     .col-lg-middle {
//         vertical-align: middle;
//     }
//     .col-lg-bottom {
//         vertical-align: bottom;
//     }
// }


// =============================================================================
// specific for pp form
// =============================================================================
// .row-height {
//     .hero_form {
//         height: 100%;

//         .panel {
//             height: 100%;
//         }
//     }
// }