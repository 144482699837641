// Functions
// 
// File containing functions used across SASS files
// 
// No styleguide reference.
// 
// Styleguide Utils.Functions

// =============================================================================
// Color Contrasting
// =============================================================================
@function tint($color, $percentage) {
	@return mix(#ffffff, $color, $percentage);
}
@function tone($color, $percentage) {
	@return mix(#808080, $color, $percentage);
}
@function shade($color, $percentage) {
	@return mix(#000000, $color, $percentage);
}

// https://lnikki.la/articles/sass-better-colour-based-on-brightness/
@function luma($c) {
  $-local-red: red(rgba($c, 1.0));
  $-local-green: green(rgba($c, 1.0));
  $-local-blue: blue(rgba($c, 1.0));
 
  @return (0.2126 * $-local-red  +
           0.7152 * $-local-green +
           0.0722 * $-local-blue) / 255;
}
@function contrast-color($color) {
    $c1: #000;
    $c2: #FFF;
    $color-luma: luma($color);
    $c1-luma: luma($c1);
    $c2-luma: luma($c2);

    $c1-diff: abs($color-luma - $c1-luma);
    $c2-diff: abs($color-luma - $c2-luma);

    @if $c1-diff > $c2-diff {
        @return shade($color, 85%);
    } @else {
        @return tint($color, 98%);
    }
}



// =============================================================================
// Background image Aspect Ratio
// =============================================================================
// Calc image intrinsic aspect ratio
@function get-img-aspect-ratio($img-url){
    $img-height: image-height(“#{$img-url}”);
    $img-width: image-width(“#{$img-url}”);
    $aspect-ratio: $img-height / $img-width;

    @return $aspect-ratio;
}