.text-left-not-xs, .text-left-not-sm, .text-left-not-md, .text-left-not-lg {
    text-align: left;
}
.text-center-not-xs, .text-center-not-sm, .text-center-not-md, .text-center-not-lg {
    text-align: center;
}
.text-right-not-xs, .text-right-not-sm, .text-right-not-md, .text-right-not-lg {
    text-align: right;
}
.text-justify-not-xs, .text-justify-not-sm, .text-justify-not-md, .text-justify-not-lg {
    text-align: justify;
}

@media (max-width: $screen-xs-max) {
    .text {
        &-left-not-xs, &-center-not-xs, &-right-not-xs, &-justify-not-xs {
            text-align: inherit;
        }
        &-left {
            &-xs {
                text-align: left;
            }
        }
        &-center {
            &-xs {
                text-align: center;
            }
        }
        &-right {
            &-xs {
                text-align: right;
            }
        }
        &-justify {
            &-xs {
                text-align: justify;
            }
        }
    }
}
@media (min-width: $screen-sm-min) and (max-width: $screen-sm-max) {
    .text {
        &-left-not-sm, &-center-not-sm, &-right-not-sm, &-justify-not-sm {
            text-align: inherit;
        }
        &-left {
            &-sm {
                text-align: left;
            }
        }
        &-center {
            &-sm {
                text-align: center;
            }
        }
        &-right {
            &-sm {
                text-align: right;
            }
        }
        &-justify {
            &-sm {
                text-align: justify;
            }
        }
    }
}
@media (min-width: $screen-md-min) and (max-width: $screen-md-max) {
    .text {
        &-left-not-md, &-center-not-md, &-right-not-md, &-justify-not-md {
            text-align: inherit;
        }
        &-left {
            &-md {
                text-align: left;
            }
        }
        &-center {
            &-md {
                text-align: center;
            }
        }
        &-right {
            &-md {
                text-align: right;
            }
        }
        &-justify {
            &-md {
                text-align: justify;
            }
        }
    }
}
@media (min-width: $screen-lg-min) {
    .text {
        &-left-not-lg, &-center-not-lg, &-right-not-lg, &-justify-not-lg {
            text-align: inherit;
        }
        &-left {
            &-lg {
                text-align: left;
            }
        }
        &-center {
            &-lg {
                text-align: center;
            }
        }
        &-right {
            &-lg {
                text-align: right;
            }
        }
        &-justify {
            &-lg {
                text-align: justify;
            }
        }
    }
}