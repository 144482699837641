.well {
    background-color: #fff;
    border-top: 5px solid $purple;
    border-radius: $border-radius-base;
    margin-bottom: $grid-gutter-width;
    padding: $grid-gutter-width;

    > legend,
    .well__legend {
        float: left;
        width: 100%;
        margin: 0;
        font-size: 1em;
        border: 0 none;
    }

    h2 {
        color: shade($purple, 50%);
        font-size: 1.1em;
        font-weight: 700;
        margin-top: 0;
        margin-bottom: $grid-gutter-width;
        text-transform: uppercase;
    }

    .form-group {
        h2 {
            margin-bottom: 0;
        }
        
        .form-horizontal &:last-of-type {
            margin-bottom: 0;
        }
        // Feedback states
        &.has-success label {
            color: $state-success-text;
        }
        &.has-warning label {
            color: $state-warning-text;
        }
        &.has-error label {
            color: $state-danger-text;
        }
    }


    hr {
        margin: ($grid-gutter-width/2) (-$grid-gutter-width) $grid-gutter-width;
        border-color: $body-bg;
        border-width: 5px;

        &.visible-xs {
            margin: ($grid-gutter-width/2) (-$grid-gutter-width);
            // &:last-of-type {
            //     display: none !important;
            // }
        }
    }
}