$checkbox: 1.2em;

.password-options {
	font-size: .75em;
	margin-top:.5em;

	p {
		font-weight: bold;
	}

	ul {
		// margin-left: -$grid-gutter-width;
		// margin-right: -$grid-gutter-width;
		// overflow: auto;
		padding: 0;
		list-style: none;
	}

	li {
		// box-sizing:border-box;
		display: block;
		// float: left;
		line-height: 1.4;
		margin-bottom: .7em;
		// overflow: visible;
		padding: 0 ($grid-gutter-width / 2) 0 ($checkbox * 2.5);
		position: relative;
		transition: transform .2s linear;
		// width: 50%;
	}

	.has-error & {
		color: $brand-danger;
	}
}

.password-options li,
.pw_check {

	&::after {
		border-right: 2px solid green;
		border-bottom: 2px solid green;
		content: "";
		display: block;
		left: ($checkbox * 1.4);
		height: $checkbox;
		// background-color: purple;
		position: absolute;
		top: -($checkbox /4);
		transform: scale(0);
		width: ($checkbox /2);
	}

	&::before {
		border: 1px solid gray;
		border-radius: 100%;
		content: "";
		display: block;
		height: 1.2em;
		left: $checkbox;
		position: absolute;
		top: .1em;
		width: 1.2em;
	}

	&.verify {
		color: gray;
		&::after {
			transform: scale(1) rotate(35deg);
		}
	}
}

.pw_check {
	font-size: .75em;
	right: ($checkbox * 2);
	top: ($checkbox * .67);
	position: absolute;

	&::before {
		left: 0;
	}
	&::after {
		left: ($checkbox /2.4);
	}
}