.col-form-legend {
    float: none !important;
}

.radiogroup  {
    overflow: hidden;
    display: flex;
    flex-wrap: wrap;

    .radiogroup-control-label {
        cursor: pointer;
        flex: 0 1 50%;
        margin-bottom: 0;
        display: flex;
        align-content: center;
        align-items: stretch;
        justify-content: flex-start;
        margin-top: -1px;
        margin-left: -1px;

        @media only screen and (max-width: $screen-sm-min) {
            min-height: 48px;

            &:first-child,
            &:first-child + .radiogroup-control-label {
                margin-top: 0;
            }

            &:nth-child(2n+1) {
                margin-left: 0;
            }

            &:first-child {
                .radiogroup-control-label-span {
                    @include border-top-left-radius($btn-border-radius-base);
                }

                + .radiogroup-control-label {
                    .radiogroup-control-label-span {
                        @include border-top-right-radius($btn-border-radius-base);
                    }
                }
            }

            &:last-child {
                .radiogroup-control-label-span {
                    @include border-bottom-right-radius($btn-border-radius-base);
                }
            }

            &:not(:nth-child(odd)):nth-child(2n) + .radiogroup-control-label:last-child,
            &:nth-last-child(2) {
                .radiogroup-control-label-span {
                    @include border-bottom-left-radius($btn-border-radius-base);
                }
            }

            &:not(:nth-child(odd)):nth-last-child(2) {
                .radiogroup-control-label-span {
                    @include border-bottom-left-radius(0);
                }
            }
        }

        @include respond-to(bp-small) {
            flex: 1 1 33.33%;
            min-height: 0;

            &:nth-child(1),
            &:nth-child(2),
            &:nth-child(3) {
                margin-top: 0;
            }

            &:nth-child(3n+1) {
                margin-left: 0;
            }

            &:first-child {
                .radiogroup-control-label-span {
                    @include border-top-left-radius($btn-border-radius-base);
                }
            }

            &:nth-child(3) {
                .radiogroup-control-label-span {
                    @include border-top-right-radius($btn-border-radius-base);
                }
            }

            &:nth-child(3n) {

                & + .radiogroup-control-label:not(:first-child):nth-last-child(3),
                & + .radiogroup-control-label:not(:first-child):nth-last-child(2) {
                    .radiogroup-control-label-span {
                        @include border-bottom-left-radius($btn-border-radius-base);
                    }
                }
            }

            &:last-child {
                .radiogroup-control-label-span {
                    @include border-bottom-right-radius($btn-border-radius-base);
                }
            }

            &:first-child + .radiogroup-control-label:last-child {
                .radiogroup-control-label-span {
                    @include border-top-right-radius($btn-border-radius-base);
                }
            }

            &:first-child:nth-last-child(2),
            &:first-child:nth-last-child(3),
            &:nth-child(3n) + .radiogroup-control-label:last-child {
                .radiogroup-control-label-span {
                    @include border-bottom-left-radius($btn-border-radius-base);
                }
            }
        }

        .radiogroup-control-label-span {
            background-clip: padding;
            border: 1px solid $input-border;
            box-sizing: border-box;
            color: contrast-color(#fff);
            padding: $padding-base-vertical $padding-base-horizontal;
            line-height: $line-height-base;
            text-align: center;
            flex: 1 1 100%;
            display: flex;
            align-items: center;
            justify-content: center;

            @each $state, $color in ( ("valid" #20BF55), ("invalid" #95190C) ) {
                .was-validated &:#{$state},
                &.is-#{$state} {
                    border-color: $color;

                    &:focus {
                        box-shadow: 0 0 0 .2rem rgba($color,.25);
                    }

                    ~ .invalid-feedback,
                    ~ .invalid-tooltip {
                        display: block;
                    }
                }
            }
        }

        .radiogroup-form-control {
            @extend %visuallyhidden;
            margin-right: 100px;

            &:hover + .radiogroup-control-label-span,
            &:focus + .radiogroup-control-label-span {
                background-color: shade(#fff, 7.5%);
            }
            &:focus + .radiogroup-control-label-span {
                border-color: $input-border;
            }
            &:checked {
                & + .radiogroup-control-label-span {
                    background-color: $logo-blue;
                    background-image: linear-gradient(rgba(0,0,0,.1), transparent);
                    box-shadow: 0 .1em .1em rgba(0,0,0,.15) inset, 0 .45em 0 .1em rgba(0,0,0,.05) inset;
                    border-color: $logo-blue;
                    color: contrast-color($logo-blue);
                }
                &:hover + .radiogroup-control-label-span,
                &:focus + .radiogroup-control-label-span {
                    background-image: linear-gradient(rgba(0,0,0,.2), transparent);
                }
            } 
        }    
    }
}