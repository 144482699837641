@-ms-viewport{
    width: device-width;
}

html {
    min-height: 100%;
    position: relative;
    font-size: 100%;
}

body {
    margin-bottom: 190px;

    &.grayscale {
        @include grayscale_element(100%, $body-bg);
    }
}

object, img, embed:not([id*="ClickTale"]) {
    border: 0 none;
    height: auto;
    vertical-align: middle;
}

object, iframe, img, embed:not([id*="ClickTale"]) {
    max-width: 100%;
}

label {
    font-weight: 400;
}

h1, .h1 { color: darken($purple, 24%); }

h1, .h1,
h2, .h2,
h3, .h3,
h4, .h4,
h5, .h5,
h6, .h6 {
    text-transform: uppercase;
}

.text-transform-off {
    text-transform: none;
}

em, strong {
    font-family: "PT Sans", Helvetica, Arial, sans-serif;
}

strong {
    font-weight: 700;
    font-style: normal;
}

em {
    font-weight: 400;
    font-style: italic;
}

svg {
    width: 100%;
    height: auto;
}