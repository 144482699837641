/**********************************************
    PANEL FLEX
**********************************************/
.panel-flex {
  @include respond-to(bp-small) {
    display: table;

    > .row {
      display: table-row;
      margin: 0;

      [class^="col-"] {
        border-radius: inherit;
        display: table-cell;
        float: none;
        vertical-align: top;

        &:first-child {
          @include border-left-radius($panel-border-radius);
        }
        &:last-child {
          @include border-right-radius($panel-border-radius);
        }
      }
    }
    @supports (display:flex) {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-content: stretch;
      align-items: stretch;

      > .row {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-content: stretch;
        align-items: stretch;

        [class^="col-"] {
          display: flex;
          justify-content: space-between;
          align-content: stretch;
          align-items: flex-start;
        }
      }
    }
  }
}

.experian {
  &-logo {
    $width: 299;
    $height: 97;
    background: url(../images/experian.svg) no-repeat center/contain;
    height: 0;
    max-width: 75%;
    width: 100%;
    padding-top: percentage($height / $width);
    margin: 0 auto ($grid-gutter-width/2);
    overflow: hidden;
    display: block;
  }

  .checkbox {

    label {
      padding: .5em 1em .5em 3em;
      border-radius: $border-radius-base;
      display: table;
      vertical-align: middle;
      @include background-gradient-vertical(nth($experian-colors, 4), 0%, nth($experian-colors, 2), 100%);
      color: #fff;
      font-weight: bold;

      input {
        position: absolute;
        opacity: 0;
      }

      .faux_checkbox {

        &:before,
        &:after {
          // top: 50%;
          left: 1em;
          border: 2px solid nth($experian-colors, 1);
          transition: all;
          transition-duration: 250ms;
          backface-visibility: hidden;
          // position: absolute;
          content: "";
          @include vertical-align(absolute);
        }

        &:before {
          background-color: #fff;
          width: 1.5em;
          height: 1.5em;
          border-radius: 3em;
        }

        &:after {
          opacity: 0;
          transform: scale(0) rotate(80deg);
          width: $font-size-base;
          height: $font-size-base/2.25;
          border-right: 0 none;
          border-top: 0 none;
          border-bottom: 3px solid nth($experian-colors, 5);
          border-left: 3px solid nth($experian-colors, 5);
          border-bottom-left-radius: 2px;
          left: 1.3em;
          top: 42.5%;
        }
      }

      :checked {
        + .faux_checkbox {
          // &:before {
          //  transform: scale(0);
          // }
          &:after {
            transform: scale(1) rotate(-50deg);
            opacity: 1;
          }
        }
      }
    }
  }

  &-color {
    position: relative;
    margin: 0 ($grid-gutter-width/2);

    @include respond-to(bp-small) {
      margin: 0;

      * {
        z-index: +1;
      }

      &::after,
      &::before {
        content: "";
        position: absolute;
        bottom: 0;
        width: 100%;
        height: 100%;
      }

      &::after {
        right: 0;
        background-image: radial-gradient(circle at bottom right, rgba(#72217b, .55), transparent);
      }

      &::before {
        left: 0;
        background-image: radial-gradient(circle at bottom left, rgba(#D92B80, .65), transparent 50%);
      }
    }
  }
}
