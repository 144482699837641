.radio-pills {
    text-align: center;

    label {
        margin: ($form-group-margin-bottom / 4);
        cursor: pointer;
        border-radius: 3em;

        span {
            text-align: center;
            background-color: #fff;
            border: 1px solid $input-border;
            color: $input-color;

            display: inline-block;
            border-radius: 3em;
            padding: $padding-base-vertical $padding-base-horizontal;
        }

        input {
            @extend %visuallyhidden;

            &:hover+span,
            &:focus+span, {
                background-color: darken($gray-lighter, 5%);
            }

            &:focus,
            &:checked {
                &+span {
                    border-color: $input-border-focus;
                }
            }

            &:checked {
                &+span {
                    background-color: $logo-blue;
                    background-image: linear-gradient(rgba(0, 0, 0, .15), transparent);
                    box-shadow: 0 .1em .1em rgba(0, 0, 0, .2) inset, 0 .45em 0 .1em rgba(0, 0, 0, .05) inset;
                    color: contrast-color($logo-blue);
                }

                &:hover+span,
                &:focus+span {
                    background-image: linear-gradient(rgba(0, 0, 0, .25), transparent);
                }
            }
        }
    }

    &.experian {
        label {
            input {
                &:checked {
                    & + span {
                        @include background-gradient-vertical(nth($experian-colors, 4), 0%, nth($experian-colors, 2), 100%);
                        color: #fff;
                    }
                }
            }
        }

        .kbaq.has-error & {
            label {
                span {
                    border-color: $brand-danger;
                }
            }
        }
    }
}