$inactiveColor:     $progress-bg;
$inactiveDotColor:  #fff;
$activeColor:       $pale-violet;
$activeDotColor:    $violet;
$stepNameColor:     #595959;
$dotRadius:         16px;
$barThickness:      $dotRadius * .66;
$stepNameSize:      1em;

.multistep {
    display: table;
    width: 100%;
    padding-left: ($grid-gutter-width/2);
    padding-right: ($grid-gutter-width/2);
    margin-top: $line-height-computed;
    margin-bottom: ($line-height-computed*2);
    counter-reset: section;
    font-size: 0.875em;

    .multistep-step {
        display: table-cell;
        padding: 0;
        position: relative;
        margin-bottom: 10px;
        width: 12.5%;

        .multistep-stepname {
            color: $stepNameColor;
            display: none;
            font-size: $stepNameSize;
            margin-bottom: $dotRadius;
            white-space: nowrap;
            @include respond-to(bp-small) {
                display: block;
            }
        }

        .multistep-dot {
            background: $inactiveColor;
            border-radius: 50%;
            display: block;
            font-weight: bold;
            left: 50%;
            line-height: 1.725;
            margin-top: -$dotRadius;
            margin-left: -$dotRadius + .5;
            padding: $dotRadius;
            position: absolute;
            right: 50%;
            width: $dotRadius * 2;
            // height: $dotRadius * 2;
            &:after {
                @include abs-pos( ($dotRadius/4) ($dotRadius/4) ($dotRadius/4) ($dotRadius/4) );
                @include border-radius(50px);
                background: $inactiveDotColor;
                // content: ' ';
                color: $inactiveColor;
                content: counter(section);
                counter-increment: section;
                text-align: center;
            }
        }

        .progress {
            position: absolute;
            width: 100%;
            height: $barThickness;
            margin-top: -$barThickness / 2;
            box-shadow: none;
            border-radius: 0;

            .progress-bar {
                width: 0px;
                box-shadow: none;
                background: $activeColor;
            }
        }

        &:first-child > .progress {
            left: 50%;
            width: 50%;
        }

        &:last-child > .progress {
            width: 50%;
        }

        &.complete {
            .multistep-dot {
                background: $activeColor;

                &:after {
                    background: $activeDotColor;
                    color: #fff;
                }
            }

            .progress > .progress-bar {
                width: 100%;
            }
        }

        &.next:hover {
            .multistep-dot {
                background: $activeColor;
            }

            .multistep-dot:after {
                background: $activeDotColor;
                color: #fff;
            }
        }

        &.current {
            .multistep-dot {
                background: $activeDotColor;

                &:after {
                    background: #fff;
                    color: $activeDotColor;
                }
            }

            .progress > .progress-bar {
                width: 50%;
            }

            &:first-child > .progress > .progress-bar {
                width: 0%;
            }

            &:last-child > .progress > .progress-bar {
                width: 100%;
            }
        }

        a.multistep-dot {
            pointer-events: none;
        }

        &.next a.multistep-dot {
            pointer-events: all;
        }
    }
}