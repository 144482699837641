body {
    &.login, &.logout {
        background-image: url(../images/planfinder-home-bg.jpg);
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
        display: flex;
        overflow: visible;
    }
}

.login, .logout {
    display: flex;
    flex-direction: column;
    margin-bottom: 0;
    height: 100vh;
    height: 100dvh;
    @include respond-to(bp-small) {
        width: 100vw;
    }

    .navbar-payplan {
        flex: 0 0 auto;
        border-radius: 0;
        // min-height: ($logo-height + ($logo-height/2)) + px;

        .navbar-header { float: none; }
    }

    .cover-container {
        justify-content: center;
        display: flex;
        flex-direction: column;
        flex: 1 1 50%;

        .cover-main {
            margin-top: 0;
            justify-content: center;
        }
    }

    #footer {
        height: auto;
        position: relative;

        flex: 0 1 20%;
    }

    .btn-lg {
        font-size: 140%;
    }
}

.form-signin{
    input[type="email"] {
        @include border-bottom-radius(0);
        margin-bottom: -1px;
    }
    input[type="password"] {
        @include border-top-radius(0);
    }
    .btn {
        font-size: 1.4em;
        color: #fff;
        text-transform: uppercase;
    }
}

.form-registration{
    input#registration_firstName,
    input#registration_emailAddress_first{
        @include border-bottom-radius(0);
        margin-bottom: -1px;
    }

    input#registration_surname,
    input#registration_emailAddress_second{
        @include border-top-radius(0);
    }
    .btn {
        font-size: 1.4em;
        color: #fff;
        text-transform: uppercase;
    }

    .g-recaptcha > div {margin:auto;}
}