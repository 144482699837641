#ui-id-1 {
	// For postcode lookup overflow problem
	max-height: 12rem;
	overflow: scroll;
}

.ui-autocomplete {
	position: absolute;
	top: 100%;
	left: 0;
	z-index: 1000;
	float: left;
	display: none;
	min-width: 160px;
	// _width: 160px;
	padding: 5px 0;
	margin: 2px 0 0 0;
	list-style: none;
	background-color: #ffffff;
	border-color: #ccc;
	border-color: rgba(0, 0, 0, 0.2);
	border-style: solid;
	border-width: 1px;
	border-radius: 5px;
	box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
	background-clip: padding-box;
	// *border-right-width: 2px;
	// *border-bottom-width: 2px;

	.ui-menu-item {
		display: block;
		padding: 3px 20px;
		clear: both;

		&:hover,
		&:focus {
			background-color: #f5f5f5;
		}

		// > a.ui-corner-all {
		// 	display: block;
		// 	padding: 3px 15px;
		// 	clear: both;
		// 	font-weight: normal;
		// 	line-height: 18px;
		// 	color: #555555;
		// 	white-space: nowrap;

		// 	&.ui-state-hover, &.ui-state-active {
		// 		color: #ffffff;
		// 		text-decoration: none;
		// 		background-color: #0088cc;
		// 		border-radius: 0px;
		// 		background-image: none;
		// 	}
		// }
	}
}

.ui-helper-hidden-accessible { display: none; }