// Radio buttons
//
// Radio fields styled like buttons
//
// Markup: _radio-buttons.hbs
//
// :hover             - Hover state
// :focus             - Focus state
// :checked           - Checked state
//
// Styleguide Components.button (organization, just as before)
.radio-buttons {
    overflow: hidden;
    display: table;
    position: relative;
    width: 100%;

    @include respond-to(bp-small) {
        &,
        &.input-group {
            width: auto;

            &.full-width {
                width: 100%;
            }
        }
    }

    label {
        display: table-cell;
        margin-bottom: 0;

        input { margin-right: 100px; bottom:0; }

        + label,
        + .input-group-addon {
            margin-left: -1px;
        }

        span {
            text-align: center;
            padding: 6px ($grid-gutter-width/2);
            display: block;
            background-color: #fff;
            border: 1px solid $input-border;
            border-right: 0 none;
            color: $input-color;
            // Feedback states
            .has-success & {
                // background-color: $state-success-bg;
                border-color: $state-success-text;
                // color: $state-success-text;
            }
            .has-warning & {
                // background-color: $state-warning-bg;
                border-color: $state-warning-text;
                // color: $state-warning-text;
            }
            .has-error & {
                // background-color: $state-danger-bg;
                border-color: $state-danger-text;
                // color: $state-danger-text;
            }
        }

        &:first-child span {
            @include border-left-radius($input-border-radius);
        }

        &:last-child span {
            @include border-right-radius($input-border-radius);
            border-right: 1px solid $input-border;
            // Feedback states
            .has-success & {
                border-color: $state-success-text;
            }
            .has-warning & {
                border-color: $state-warning-text;
            }
            .has-error & {
                border-color: $state-danger-text;
            }
        }
        input {
            @extend %visuallyhidden;

            &:hover + span,
            &:focus + span,            
            &.pseudo-class-hover + span,
            &.pseudo-class-focus + span {
                background-color: darken($gray-lighter, 5%);
            }
            &:focus,
            &.pseudo-class-focus,
            &:checked,
            &.pseudo-class-checked {
                & + span {
                    border-color: $input-border-focus;
                }
            }
            &:checked,
            &.pseudo-class-checked {
                & + span {
                    background-color: $logo-blue;
                    background-image: linear-gradient(rgba(0,0,0,.15), transparent);
                    box-shadow: 0 .1em .1em rgba(0,0,0,.2) inset, 0 .45em 0 .1em rgba(0,0,0,.05) inset;
                    color: contrast-color($logo-blue);
                }
                &:hover + span,
                &:focus + span {
                    background-image: linear-gradient(rgba(0,0,0,.25), transparent);
                }
            } 
        }

    }

    .input-group-addon {
        width: 1%;
        @include respond-to(bp-small) {
            width: auto;
        }
        .tip_anchor {
            margin-right: 0;
            margin-top: 0;
        }

        &:last-child {
            border-left: 1px solid $input-border;
            // Feedback states
            .has-success & {
                border-color: $state-success-text;
            }
            .has-warning & {
                border-color: $state-warning-text;
            }
            .has-error & {
                border-color: $state-danger-text;
            }
        }
    }
}