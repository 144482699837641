@use "sass:map";
/* 	THIS FILE IS FOR QUICK HACKS AND SHOULD BE REMEDIED AS SOON AS POSSIBLE WITH ACTUAL FIXES */
@forward "../base/colors";

    
@each $key, $value in $brand-colors {
    .bg-#{$key} {
        background-color: #{$value};
    }
    .text-#{$key} {
        color: #{$value};
    }
    .border-#{$key} {
        border-color: #{$value};
    }
}

@each $selector in (0, 1, 2, 3, 4) {
    .border-#{$selector} {
        border-width: $selector+px;
    }

    @each $abbr, $direction in ("t": "top", "r": "right", "b": "bottom", "l": "left" ) {
        .border-#{$abbr}-#{$selector} {
            border-#{$direction}-width: $selector+px;
        }
    }
}

@each $key in (dotted, dashed, solid, double, groove, ridge, inset, outset, none, hidden) {
    .border-#{$key} {
        border-style: #{$key};
    }
}

.flex { display: flex; }
.inline-flex { display: inline-flex; }
.flex-auto { flex: 0 1 auto; }
.flex-initial { flex: 1 1 auto; }
.flex-none { flex: 0 0 auto; }
.flex-col { flex-direction: column; }
.flex-col-reverse { flex-direction: column-reverse; }
.flex-row { flex-direction: row; }
.flex-row-reverse { flex-direction: row-reverse; }

// Determines how items are laid out on the cross axis
@each $key in (baseline, flex-start, flex-end, center, stretch) {
    .items-#{$key} {
        align-items: #{$key};
    }
}

// Attempts to distribute extra space on the main axis
@each $key in (flex-start, flex-end, center, space-between, space-around, space-evenly) {
    .justify-#{$key} {
        justify-content: #{$key};
    }
}

// Only has an effect with more than one line of content.
@each $key in (flex-start, flex-end, center, space-between, space-around, stretch) {
    .content-#{$key} {
        align-content: #{$key};
    }
}

.card-icon {
    padding: .5em; // was .75em before migration from demo to product
    text-align: center;
    border-radius: 100%;
    line-height: 1;
    display: block;
    margin-right: 1em;
}

.icon {
    width: 1em;
    height: 1em;
    font-size: inherit;
    vertical-align: middle;
    fill: currentColor;

    &-1\/2x {
        width: 1.5em;
        height: 1.5em;
    }
    &-2x {
        width: 2em;
        height: 2em;
    }
    &-3x {
        width: 3em;
        height: 3em;
    }
}


.m-x-auto {
    margin-left: auto;
    margin-right: auto;
}

.bg-primary-red-100 {
    $setColor: map-get($brand-colors, "primary-red");
    background-color: tint($setColor, 90%);
}

.bg-primary-blue-grey-100 {
    $setColor: map-get($brand-colors, "primary-blue-grey");
    background-color: tint($setColor, 90%);
}

.bg-primary-blue-100 {
    $setColor: map-get($brand-colors, "primary-blue");
    background-color: tint($setColor, 90%);
}

.bg-primary-orange-100 {
    $setColor: map-get($brand-colors, "primary-orange");
    background-color: tint($setColor, 90%);
}

.bg-primary-purple-100 {
    $setColor: map-get($brand-colors, "primary-purple");
    background-color: tint($setColor, 90%);
}

.list-group-header {
    font-size: 13px;
    font-weight: 700;
    // color: #959595;
    text-transform: uppercase;
    letter-spacing: 1px;

}

@each $key in (0, 5, 10, 15) {
    @each $abbr, $direction in ("t": "top", "r": "right", "b": "bottom", "l": "left" ) {
        .m-#{$abbr}-#{$key} {
            margin-#{$direction}: $key+px;
        }
        .p-#{$abbr}-#{$key} {
            padding-#{$direction}: $key+px;
        }
    }

    .m-#{$key} {
        margin: $key+px
    }
    
    .m-x-#{$key} {
        margin-left: $key+px;
        margin-right: $key+px;
    }    
    .m-y-#{$key} {
        margin-bottom: $key+px;
        margin-top: $key+px;
    }

    .p-#{$key} {
        padding: $key+px
    }
    .p-x-#{$key} {
        padding-left: $key+px;
        padding-right: $key+px;
    }
    .p-y-#{$key} {
        padding-bottom: $key+px;
        padding-top: $key+px;
    }
}

@each $key, $value in ("1#{\/}4": 25%, "1#{\/}3": 33.33%, "1#{\/}2": 50%, "3#{\/}4": 75%, "full": 100%) {
    .w-#{$key} {
        width: $value;
    }
}