// Mixins
// 
// File containing mixins used across SASS files
// 
// No styleguide reference.
// 
// Styleguide Utils.Mixins


// =============================================================================
// Mix Blend Mode
// thenewcode.com/707/PhotoShop-In-The-Browser-Understanding-CSS-Blend-Modes
// multiply, screen, overlay, darken, lighten, color-dodge, color-burn,
// hard-light, soft-light, difference, exclusion
// =============================================================================
@mixin mix-blend-mode($filter) {
	mix-blend-mode: $filter;
}

@mixin background-blend-mode($color, $blend, $image:false) {
    @if $image != false {
        background-image: image-url('#{$image}');
    }	
	background-color: $color;
	background-blend-mode: $blend;

    .mod-no-backgroundblendmode & {
        position: relative;
        overflow: hidden;

        &::before {
            @include abs-pos(0px 0px 0px 0px);
            background-color: rgba(darken($color, 15%), 0.75);
            content: "";
            display: block;
            height: 200%;
            margin: 0;
            width: 200%;
        }
    }
}
// =============================================================================
// Break points
// =============================================================================
@mixin respond-to($media) {
	@if $media == bp-small {
		@media only screen and (min-width: $screen-sm-min) { @content; }
	} @else if $media == bp-medium {
		@media only screen and (min-width: $screen-md-min) { @content; }
	} @else if $media == bp-large {
		@media only screen and (min-width: $screen-lg-min) { @content; }
	}
}

// =============================================================================
// Box Shadow
// @include box-shadow(-1px -1px 2px 0 rgba(0, 0, 0, .05), 0 1px 1px 0 white inset), ...
// =============================================================================
@mixin box-shadow($shadow1, $shadow2:false, $shadow3:false, $shadow4:false, $shadow5:false) {
	$params: $shadow1;
	@if $shadow2 { $params: $shadow1, $shadow2; }
	@if $shadow3 != false { $params: $shadow1, $shadow2, $shadow3; }
	@if $shadow4 != false { $params: $shadow1, $shadow2, $shadow3, $shadow4; }
	@if $shadow5 != false { $params: $shadow1, $shadow2, $shadow3, $shadow4, $shadow5; }
	box-shadow: $params;
}

// =============================================================================
// Text Shadow
// @include text-shadow(0 1px 0 #000, 0 1px 0 #000);
// =============================================================================
@mixin text-shadow($shadow1, $shadow2:false) {
    $params: $shadow1;
    @if $shadow2 {
        $params: $shadow1, $shadow2;
    }

    text-shadow: $params;
}

// =============================================================================
// Position Absolute
// @include abs-pos(0px 0px 0px 0px);
// =============================================================================
@mixin abs-pos($coordinates: 0 0 0 0) {
    $top: nth($coordinates, 1);
    $right: nth($coordinates, 2);
    $bottom: nth($coordinates, 3);
    $left: nth($coordinates, 4);

    @if not(unitless($top)) {
        top: $top;
    }
    @if not(unitless($right)) {
    	right: $right;
    }
    @if not(unitless($bottom)) {
    	bottom: $bottom;
    }
    @if not(unitless($left)) {
    	left: $left;
    }
    position: absolute;
}


// =============================================================================
// Vertical align
// @include vertical-align();
// =============================================================================
@mixin vertical-align($position: relative) {
    position: $position;
    top: 50%;
    transform: translateY(-50%);
}


// =============================================================================
// Horizontal align
// @include horizontal-align();
// =============================================================================
@mixin horizontal-align($position: relative) {
  position: $position;
  left: 50%;
  transform: translateX(-50%);
}

// =============================================================================
// All Corners rounded
// =============================================================================
@mixin border-radius($radius) {
	border-radius: $radius;
	background-clip: padding-box;
}

// =============================================================================
// Specific sides rounded
// =============================================================================
@mixin border-top-radius($radius) {
	border-top-right-radius: $radius;
	border-top-left-radius: $radius;
	background-clip: padding-box;
}
@mixin border-right-radius($radius) {
	border-top-right-radius: $radius;
	border-bottom-right-radius: $radius;
	background-clip: padding-box;
}
@mixin border-bottom-radius($radius) {
	border-bottom-right-radius: $radius;
	border-bottom-left-radius: $radius;
	background-clip: padding-box;
}
@mixin border-left-radius($radius) {
	border-top-left-radius: $radius;
	border-bottom-left-radius: $radius;
	background-clip: padding-box;
}


// =============================================================================
// Specific corners rounded
// =============================================================================
@mixin border-top-right-radius($radius) {
	border-top-right-radius: $radius;
	background-clip: padding-box;
}
@mixin border-top-left-radius($radius) {
	border-top-left-radius: $radius;
	background-clip: padding-box;
}
@mixin border-bottom-right-radius($radius) {
	border-bottom-right-radius: $radius;
	background-clip: padding-box;
}
@mixin border-bottom-left-radius($radius) {
	border-bottom-left-radius: $radius;
	background-clip: padding-box;
}

// =============================================================================
// Center a block
// =============================================================================
@mixin center-block {
	display: block;
	margin-left: auto;
	margin-right: auto;
	// @include rem-fallback(margin-left, auto);
	// @include rem-fallback(margin-right, auto);
}

// =============================================================================
// Background gradient
// =============================================================================
@mixin background-gradient($direction, $color1, $value1, $color2, $value2) {
    background-image: linear-gradient($direction, $color1 $value1, $color2 $value2);
}

@mixin background-gradient-vertical($color1, $value1, $color2, $value2) {
  background-image: linear-gradient(to bottom, $color1 $value1,$color2 $value2);
}

@mixin background-gradient-horizontal($color1, $value1, $color2, $value2) {
	background-image: linear-gradient(to right, $color1 $value1,$color2 $value2); /* Standard syntax; must be last */
}

@mixin background-gradient-radial($color1,$color2) {
	background-image: radial-gradient(ellipse at center, $color1 0%, $color2 100%);
}

@mixin stripes($colors, $direction: "to bottom") {  
    $stripe-width: 100% / length($colors);
    $auto:         if( type-of(nth($colors, 1)) == list, false, true );
    $gradient:     ();
    
    @for $i from 1 through length($colors) {
        $item: nth($colors, $i);
        $dump: ();
      
        @if $auto == true {
            $dump: $item $stripe-width * ($i - 1), $item $stripe-width * $i;
        }
      
        @else {   
            @if $i > 1 {
                $previous-item: nth($colors, $i - 1);
                $dump: append($dump, nth($item, 1) nth($previous-item, 2));
            }
            $dump: append($dump, $item);
        }

        $gradient: join($gradient, $dump, comma);
    }		

    @include background-image(linear-gradient($direction, $gradient));
}

// =============================================================================
// Background transparency and convert HEX to RGBA
// =============================================================================
@mixin background-opacity($color, $opacity: 0.3) {
    background: $color; /* The Fallback */
    background: rgba($color, $opacity);
}


// =============================================================================
// Opacity
// =============================================================================
// @mixin opacity($opacity) {
// 	opacity: $opacity;
// 	$opacity-ie: $opacity * 100;
// 	filter: alpha(opacity=$opacity-ie); //IE8
// }

// =============================================================================
// Animations and keyframes
// =============================================================================
// animation keyframes
// @include keyframes(ANIMATIONNAME) {
//   0% { ATTRIBUTE: VALUE; ATTRIBUTE: VALUE; }
//   50% { ATTRIBUTE: VALUE; ATTRIBUTE: VALUE; }
//   100% { ATTRIBUTE: VALUE; ATTRIBUTE: VALUE; }
// }
@mixin keyframes($animationName) {
    // @-webkit-keyframes #{$animationName} {
    //     @content;
    // }
    // @-moz-keyframes #{$animationName} {
    //     @content;
    // }
    // @-o-keyframes #{$animationName} {
    //     @content;
    // }
    @keyframes #{$animationName} {
        @content;
    }
}

// 	// ================================================================
// 	// http://css-tricks.com/snippets/css/keyframe-animation-syntax/
// 	// ================================================================
@mixin animate($str...) {
	// -webkit-animation: #{$str};
	//    -moz-animation: #{$str};
	//     -ms-animation: #{$str};
	//      -o-animation: #{$str};
	        animation: #{$str};
// 	// ================================================================
    // animation-name: $animationName;
    // animation-duration: 4s; /* or: Xms */
    // animation-iteration-count: 10;
    // animation-direction: alternate; /* or: normal */
    // animation-timing-function: ease-out; /* or: ease, ease-in, ease-in-out, linear, cubic-bezier(x1, y1, x2, y2) */
    // animation-fill-mode: forwards; /* or: backwards, both, none */
    // animation-delay: 2s; /* or: Xms */
}

// ======================================================================
// Transition
// ======================================================================
@mixin transition($args...) {
// 	-webkit-transition: #{$args};
// 	   -moz-transition: #{$args};
// 	    -ms-transition: #{$args};
// 	     -o-transition: #{$args};
	   		transition: #{$args};
}

// =============================================================================
// Hide text
// =============================================================================
@mixin sr-only() {
    text-indent: -9999px;
    overflow: hidden;
    text-decoration: none;
    display: block;
    font-size: 0;
    text-align: start;
}
%sr-only {
    @include sr-only;
}

// =============================================================================
// SVG Background image with fall back
// =============================================================================
@mixin svg-fallback($img-name, $size:false) {
	// background-image: url('../images/#{$img-name}.svg');	
	background-image: inline("svg/#{$img-name}.svg");
	@if($size) {
		background-size: $size;
	}

	.mod-no-svg & {
		background-image: inline("#{$img-name}.png");
	}
}

// =============================================================================
// Image Aspect Ratio
// =============================================================================
$default: 100 !default;
// @mixin aspect-ratio($width: $default, $height: $default) {
// 	-moz-box-sizing: content-box;
// 	-webkit-box-sizing: content-box;
// 	box-sizing: content-box;
// 	width: $default * 1%;
// 	// max-width: $width +px;
// 	height: 0;
// 	padding: 0; /* reset */
// 	padding-bottom: floor(100% * ($height / $width));
// 	// background-image: url(image-with-4-3-aspect-ratio.svg);
// 	// background-size: cover;
// }
@mixin aspect-ratio($width, $height) {
	position: relative;
    width: $width +px;
    &::before {
        display: block;
        content: "";
        width: 100%;
        padding-bottom: percentage($height / $width);
    }
    > * {
        @include abs-pos(0px 0px 0px 0px);
    }
}



// =============================================================================
// Background image Aspect Ratio
// =============================================================================
@mixin intrinsic-ratio-bg-img($img-url) {
    background-size: contain; // note: could also use ‘cover’ but it is less supported.
    background-position: top right;
    background-repeat: no-repeat;
    padding-top: percentage(get-img-aspect-ratio($img-url));
    height: 0px !important;
    display: block;
}


// =============================================================================
// Text truncate
// =============================================================================
@mixin word-wrap() {
    overflow-wrap: break-word;
    word-wrap: break-word;
    // -ms-word-break: break-all;
    word-break: break-word;
    // -ms-hyphens: auto;
    // -moz-hyphens: auto;
    // -webkit-hyphens: auto;
    hyphens: auto;
}
%word-wrap {
    @include word-wrap;
}

@mixin text-truncate() {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
%text-truncate {
	@include text-truncate;
}

// =============================================================================
// Paragraph truncate
// ($lines = amount of lines, $orientation = vertical/horizontal)
// =============================================================================
@mixin para-truncate($lines, $orientation) {
	overflow: hidden;
	text-overflow: ellipsis;
	display: block;
    display: -webkit-box;
    max-width: 100%;
    -webkit-line-clamp: $lines;
    -webkit-box-orient: $orientation;
}




// =================================================
//  Grayscale for contrast checking
// =================================================
@mixin grayscale_element($value, $bg-color:false, $color:false) {
    filter: #{"grayscale(#{$value})"};
    @if($bg-color) {
        background-color: grayscale($bg-color);
    }
    @if($color) {
        color: grayscale($color);
    }
}
// .grayscale {
//     @include grayscale_element(100%);
// }








// =============================================================================
// Specifically target IE10 and IE11 (not Edge)
// =============================================================================
@mixin target_ie_below_edge {
    @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
        @content;
    }
}
