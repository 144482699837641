// Colors 
// 
// Colours used within the brand.
// 
// Styleguide Base.Colors




// Logo Colors 
// 
// The primary colors for branding. 
// 
// $logo-blue	-  #0095D6; Rich Electric Blue 
// $logo-pink	-  #E00069; Magenta Elephant
//
// Weight: 1
// 
// Styleguide Base.Colors.logo
$logo-blue:		#006796;
$logo-pink:		#E00069;



// Brand Colors 
// 
// The secondary colors for branding. 
// 
// $orange:			-	#EF7D00; Rè Dài Chéng Orange
// $purple:			-	#6CB1E2; Xavier Blue
// $pink:			-	#EC6089; Watermelon Water
// $green:			-	#8CCDC5; Tropical Holiday
// $violet:			-	#863B8F; Dull Violet
// $yellow:			-	#FBC900; Himawari Yellow
// $pale-blue:		-	#A2DBF0; Sora Blue
// $pale-pink:		-	#F39EA6; Sweet 60
// $pale-green:		-	#C9DD99; Lickety Split
// $pale-violet:	-	#CABBDD; Viking Diva
//
// Weight: 2
// 
// Styleguide Base.Colors.brand
$orange: 		#EF7D00;
$purple:		#6CB1E2;
$pink:			#EC6089;
$green:			#8CCDC5;
$violet:		#863B8F;
$yellow:		#FBC900;
$pale-blue:		#A2DBF0;
$pale-pink:		#F39EA6;
$pale-green: 	#C9DD99;
$pale-violet:	#CABBDD;


// $studio:			#81459A;
// $cardinal:			#BF1E4D;

$color_list: (
	$brand-primary,
	$brand-success,
	$brand-info,
	$brand-warning,
	$brand-danger,
	$orange,
	$purple,
	$pink,
	$green,
	$violet,
	$yellow,
	$pale-blue,
	$pale-pink,
	$pale-green,
	$pale-violet
);

$text-colors: (
	("default", $text-color),
	("white", #fff),
	("orange", $orange),
	("blue", $logo-blue),
	("pink", $pink),
	("dark-pink", $logo-pink),
	("green", $green),
	("purple", $violet),
	("yellow", $yellow),
	("light-blue", $pale-blue)
);

$btn_colors: (
	("orange", $orange),
	("blue", $purple),
	("pink", $pink),
	("green", $green),
	("purple", $violet),
	("yellow", $yellow),
	("light-blue", $pale-blue),
	("default", $text-color)
);

$white_text_colors: (
	("orange", darken( $orange, 11% ) ),
	("blue", darken( $purple, 21% ) ),
	("pink", darken( desaturate( $pink, 1% ), 14% ) ),
	("green", darken( $green, 31) ),
	("purple", $violet)
);

$experian-colors: (
	#0D6EB7,
	#72217B,
	#B12384,
	#D92B80,
	#004691
);


$brand-colors: (
	"logo-blue": #004d71,
	"dark-grey": #575756,
	"light-grey": #abb8c1,
	"primary-yellow": #fdc543,
	"primary-blue": #1180b0,
	"primary-red": #a91d30,
	"primary-orange": #ef7d00,
	"primary-pink": #bc2886,
	"primary-aqua": #8dcdc4,
	"primary-purple": #8d1a7c,
	"primary-green": #afcb37,
	"primary-blue-grey": #5a8b9d,
	"standard-grey": #f2f2f2,
	"transparent": transparent,
	"current": currentColor
);